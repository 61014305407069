import styled from 'styled-components';
import { colorConfig } from '../../config/globalConfig';

export const CalculatorSection = styled("div")<{icon: string}>`
  @media (min-width: 640px) {
    .radio-container { display: flex;  }
    .radio-container.column { padding-bottom: 0px; }
  }
  @media (max-width: 639px) {
    .radio-container .radioButton svg { margin-top: 13px !important; }
  }
  .radio-container { margin-bottom: -5px !important; }
  .radio-container .label { margin: 0px !important; }

  .sm { font-size: 0.75em; margin-left: 40px; margin-top: -10px; }

  .title {
    outline: none;
    display: block;
    width: 100%;
    border: none;
    cursor: pointer;
    position: relative;

    color: white;
    background: ${colorConfig.main} url(images/${props => props.icon || 'loan2019'}.png?v=1) no-repeat 0px 0px;
    background-size: 40px;
    background-position: 10px 8px;
    padding: 15px 20px;
    padding-left: 60px;
    margin: 0px;
    font-size: 16px;
    font-family: 'Nunito Sans', arial;
    font-weight: bold;
    color: white;
    text-align: left;
    align-items: center;
  }
  .title .at-right { position: absolute; top: 18px; right: 20px; }

  margin-bottom: 20px;

  .section-row { display: flex; background: #f2f7e9; position: relative; }
  .error-hint { position: absolute; z-index:1000; top: 10px; left: 99%; white-space: nowrap; }
  .error-hint .tbl-row { display: flex; align-items: center; }
  .error-hint .icon { width: 40px; height: 40px; }
  .error-hint .box { font-weight: 600; padding: 10px 15px; background: white; color: #ce1004; box-shadow: 0px 10px 20px #ccc; font-size: 0.75em; }

  .pad { padding: 2px 20px; display: flex; align-items: center; width: 100%; }
  .column { padding: 20px 30px; flex: 1; }
  .small-column { padding: 20px; }
  .label { color: #000; font-size: 16px; font-weight: 500; font-family: 'Nunito Sans', arial; text-align: left; margin-top:10px; margin-bottom: 10px; }
  .label.gray { color: #666;}
  .pad .label { flex: 1; font-family: 'Nunito Sans', arial;  }

  @media(max-width: 480px) {
    .pad { display: block; padding-right: 0px; width: auto; }
    .label { padding-top:10px; margin-bottom: 0px; }
    .pad .label { flex: none; width: auto; }
    .section-row .column {
      margin: 0px !important;
      padding: 10px 20px !important;
    }
    .ranger {
      margin-top: 20px;
      margin-bottom: 15px;
      left: 5% !important;
      width: 85% !important;
    }
  }

  @media(max-width: 800px) {
    .pad { width: calc(100% - 40px); }
    .section-row { display: block; }
    .small-column, .column { padding-top: 10px; padding-bottom: 10px; }
  }
`;

export const Centered = styled("div")<{width: number}>`
  max-width: ${props => props.width || 800}px;
  margin: 0px auto;
  @media(max-width: 860px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const OneColumnLayout = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  display: flex;

  .plus {
    width: 100%;
    display: block;
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none !important;
    padding: 0px;
  }

  .sidebar { margin-right: 20px; width: 400px; }
  .content { flex: 1; }

  @media(max-width: 1024px) {
    display: block;
    margin-left: 20px;
    margin-right: 20px;
    .sidebar { margin-right: 0px; width: 100%; }
  }

  .ranger { width: 120px;  }
  .label-choose {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 11px;
    text-align: left;
    font-weight: 400;
    color: #000;
  }

  @media(max-width: 480px) {
    .ranger {
      margin-top: 20px;
      margin-bottom: 15px;
      left: 5% !important;
      width: 85% !important;
    }
    .label-choose {
      font-size: 13px;
    }
  }

`;
