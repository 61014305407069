import React from 'react';
import { TextInput, RangerInput, Plus, Minus } from './../../Form/index';
import { Exclamation } from './../../Form/Exclamation';
import ReadOnlyInput from './../../Form/ReadOnlyInput';
import { paymentsFromDrawdowns } from './../../../services/Calc';
import { toCurrency } from './../../../services/Formats';

export const DrawdownAdditional = ({ input, output, onChangeInput, onChangeInputs }) => {
  const canAdd = () => {
    return !!input.payments.find(y => (!y.drawdown));
  };
  const onAddRow = () => {
    const newInput = {...input};
    // find first year without drawdown
    const found = input.payments.find(y => (!y.drawdown));
    if (found) {
      newInput.drawdowns.push({ year: found.year, value: 0 });
    }
    onChangeInput('drawdowns', newInput.drawdowns);
  };
  const canRemove = () => {
    return (input.drawdowns.length > 0);
  };
  const onRemoveRow = () => {
    const newInput = {...input};
    newInput.drawdowns.pop();
    onChangeInputs({
      'drawdowns': newInput.drawdowns,
      'payments': paymentsFromDrawdowns(newInput.drawdowns, newInput.payments)
    });
  };

  const onChangeYear = (row, value) => {
    const newInput = {...input};
    newInput.drawdowns[row].year = value;
    onChangeInputs({
      'drawdowns': newInput.drawdowns,
      'payments': paymentsFromDrawdowns(newInput.drawdowns, newInput.payments)
    });
  };
  const onChangeValue = (year, value) => {
    const newInput = {...input};
    input.drawdowns.forEach((y, index) => {
      if (y.year === year) {
        newInput.drawdowns[index].value = value;
      }
    });
    onChangeInputs({
      'drawdowns': newInput.drawdowns,
      'payments': paymentsFromDrawdowns(newInput.drawdowns, newInput.payments)
    });
  };

  const total = input.drawdowns.map(y => y.value).reduce((x,y) => (x+y), 0);
  return (
    <div>
      <div className="section-row" style={{ background: "#f1f1f1" }}>
        <div className="pad" style={{ fontSize: '0.75rem' }}>
          <div className="label">Additional loan facility available</div>
          <div>
            <ReadOnlyInput
              width={120} style={{ textAlign: 'right' }}
              type="currency" prefixes="&pound;"
              value={toCurrency(output.additionalLoan)}
            />
          </div>
        </div>
      </div>
      <div className="section-row" style={{ background: "#f1f1f1" }}>
        <div className="pad" style={{ fontSize: '0.75em' }}>
          <div className="label">
            See how drawing more equity from your home
            can affect the total equity retained
        </div>
        </div>
      </div>
      {input.drawdowns.map((row, index) => {
        const isError = parseFloat(total) > output.additionalLoan;
        return (
          <div className="section-row" key={index} style={{ background: "#f1f1f1" }}>
            {isError ? (
                <div className="error-hint">
                  <div className="tbl-row">
                    <div className="icon" style={{ paddingTop: 20 }}><Exclamation /></div>
                    <div className="box">
                      The amount exceeds the additional<br />
                      loan facility available
                    </div>
                  </div>
                </div>
              ): null}
            <div className="pad" style={{ fontSize: '0.75rem' }}>
              <div className="label" style={{ fontSize: '0.75rem', paddingRight: 10 }}>
                Select the year
              <br />
              and the
              <br />
              additional amount
            </div>
              <div style={{ marginRight: 20 }}>
                <RangerInput
                  sideLabel="Year"
                  className="ranger"
                  color={'#000'}
                  value={row.year} min={1} max={20}
                  inactiveColor='#bbbbbb' withLabel={true}
                  onChange={val => { onChangeYear(index, val) }} />
              </div>
              <div>
                <TextInput
                  width={120} style={{ textAlign: 'right' }}
                  type="currency" prefixes="&pound;"
                  value={row.value}
                  onChange={val => { onChangeValue(row.year, parseFloat(val, 10)) }}
                />
              </div>
            </div>
          </div>
        );
      })}
      {(canAdd()) ? (
        <div className="section-row" style={{ background: "#f1f1f1" }}>
          <div className="pad" style={{ fontSize: '0.8rem' }}>
            <button className="plus" onClick={onAddRow}>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>&nbsp;</div>
                <div>
                  Draw more equity &nbsp;
                </div>
                <Plus />
              </div>
            </button>
          </div>
        </div>
      ): false}
      {(canRemove()) ? (
        <div className="section-row" style={{ background: "#f1f1f1" }}>
          <div className="pad" style={{ fontSize: '0.8rem' }}>
            <button className="plus" onClick={onRemoveRow}>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>&nbsp;</div>
                <div>
                  Draw less equity &nbsp;
                </div>
                <Minus />
              </div>
            </button>
          </div>
        </div>
      ): false}

      <div className="section-row" style={{ background: "#f1f1f1", height: 20 }}>
      </div>
    </div>
  );
};
