import React, { useState } from 'react';
import { OneColumnLayout } from './Sections';
import { ChartLegend } from './ChartLegend';
import { ChartSeries } from './ChartSeries';
import { YearsSlider } from './YearsSlider';
import { LeftPanel } from './LeftPanel';
import { IPaymentsInput } from './../../services/types';
import { getOutput, getDefault } from './../../services/Calc';
import { YearsInput } from './YearsInput';
import { colors, titles } from './../../config/partialRepaymentConfig';

const arrN = (n: number) : Array<number> => {
  const out = [];
  for (let i = 0; i < n; i++) {
    out.push(i)
  }
  return out;
}

export const VoluntaryRepayments = () => {
  const [input, setInput] = useState<IPaymentsInput>(getDefault());
  const output = getOutput(input);

  // const { additionalLoan, minBorrow, maxBorrow } = output;
  // console.log(JSON.stringify({ additionalLoan, minBorrow, maxBorrow }))
  // console.table(output.years);
  // console.table(output.months);

  const onChangeInput = (f: string, v: any) => {
    if (
      f === 'payments' || f === 'projection' ||
      f === 'drawdowns' || f === 'yearPeriods'
    ) {
      setInput({ ...input, [f]: v });
    } else if (f === 'isJoint') {
      setInput({ ...input, [f]: v });
    } else {
      setInput({ ...input, [f]: parseFloat(v) || 0 });
    }
  };
  const onChangeInputs = (m: any) => {
    const i:any = { ...input };
    Object.keys(m).forEach(f => {
      i[f] = m[f];
    });
    setInput(i);
  };

  const nYears = output.years.length - 1;
  const series = {
    futureHousePrice: output.years.slice(0, nYears).map(y => y.futureHousePrice),
    noRepayments: output.years.slice(0, nYears).map(y => y.noRepaymentsLoanBalance),
    partialRepayment: output.years.slice(0, nYears).map(y => y.partialPaymentLoanBalance)
  };
  const noData = series.futureHousePrice.length === 0 || series.noRepayments.length === 0;
  const debugTerms = 0;
  return (
    <div>
      <OneColumnLayout>
        <div className="sidebar">
          <LeftPanel {...{ input, output, onChangeInput, onChangeInputs }} />
        </div>
        <div className="content">
          <ChartLegend {...{ colors, titles }} />
          <ChartSeries series={series} hideChart={noData} />
          <YearsSlider {...{ input, output, onChangeInput}} />
          {debugTerms ? (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: "top" }}><pre style={{ textAlign: 'right', color: 'gray' }}>payments: {JSON.stringify(arrN(20), null, 2)}</pre></td>
                    <td style={{ verticalAlign: "top" }}><pre style={{ textAlign: 'right', color: 'purple' }}>payments: {JSON.stringify(input.payments.map(p => p.payment ? p.payment.toFixed(2) : '0.00').slice(0, 20), null, 2)}</pre></td>
                    <td style={{ verticalAlign: "top" }}><pre style={{ textAlign: 'right', color: 'purple' }}>interest: {JSON.stringify(output.years.map(y => y.partialPaymentInterest.toFixed(2)).slice(0, 20), null, 2)}</pre></td>
                    <td style={{ verticalAlign: "top" }}><pre style={{ textAlign: 'right', color: 'purple' }}>balance: {JSON.stringify(output.years.map(y => y.partialPaymentLoanBalance.toFixed(2)).slice(0, 20), null, 2)}</pre></td>
                    <td style={{ verticalAlign: "top" }}><pre style={{ textAlign: 'right', color: 'green' }}>drawdown: {JSON.stringify(input.payments.map(p => p.drawdown ? p.drawdown.toFixed(2): "0.00").slice(0, 20), null, 2)}</pre></td>
                    <td style={{ verticalAlign: "top" }}><pre style={{ textAlign: 'right', color: 'green' }}>interest: {JSON.stringify(output.years.map(y => y.noRepaymentsInterest.toFixed(2)).slice(0, 20), null, 2)}</pre></td>
                    <td style={{ verticalAlign: "top" }}><pre style={{ textAlign: 'right', color: 'green' }}>no repayment: {JSON.stringify(output.years.map(y => y.noRepaymentsLoanBalance.toFixed(2)).slice(0, 20), null, 2)}</pre></td>
                  </tr>
                </tbody>
              </table>
              <table>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: "top" }}><pre style={{ textAlign: 'right', color: 'gray' }}>payments: {JSON.stringify(arrN(12*debugTerms+1), null, 2)}</pre></td>
                    <td style={{ verticalAlign: "top" }}><pre style={{ textAlign: 'right', color: 'purple' }}>payments: {JSON.stringify(output.months.map(y => y.payments.toFixed(2)).slice(0, 12*debugTerms+1), null, 2)}</pre></td>
                    <td style={{ verticalAlign: "top" }}><pre style={{ textAlign: 'right', color: 'purple' }}>interest: {JSON.stringify(output.months.map(y => y.interestPayment.toFixed(2)).slice(0, 12*debugTerms+1), null, 2)}</pre></td>
                    <td style={{ verticalAlign: "top" }}><pre style={{ textAlign: 'right', color: 'purple' }}>balance column E: {JSON.stringify(output.months.map(y => y.balanceE.toFixed(2)).slice(0, 12*debugTerms+1), null, 2)}</pre></td>
                    <td style={{ verticalAlign: "top" }}><pre style={{ textAlign: 'right', color: 'green' }}>no repayment: {JSON.stringify(output.months.map(y => y.interestNoRepayment.toFixed(2)).slice(0, 12*debugTerms+1), null, 2)}</pre></td>
                    <td style={{ verticalAlign: "top" }}><pre style={{ textAlign: 'right', color: 'green' }}>balance column J: {JSON.stringify(output.months.map(y => y.balanceJ.toFixed(2)).slice(0, 12*debugTerms+1), null, 2)}</pre></td>
                  </tr>
                </tbody>
              </table>
            </div>
          ): null}
        </div>
      </OneColumnLayout>
      <YearsInput {...{ input, output, onChangeInput, onChangeInputs }} />
    </div>
  );
};
