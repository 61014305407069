// import { toPounds, toCurrency } from './Formats';
import {
    IPaymentsInput, IYearValue, IPaymentsYear,
    IPaymentsOutput, IPaymentsOutputMonth, IPaymentsOutputYear
} from './types';

export const paymentsFromDrawdowns = (
    drawdowns: Array<IYearValue>,
    payments: Array<IPaymentsYear>
): Array<IPaymentsYear> => {
    return payments.slice().map(p => {
        let sum = 0;
        drawdowns.forEach(d => {
            if (d.year === p.year) {
                sum += d.value;
            }
        });
        p.drawdown = sum;
        return p;
    });
}

export const drawdownsFromPayments = (
    payments: Array<IPaymentsYear>
): Array<IYearValue> => {
    const out: Array<IYearValue> = [];
    payments.slice().forEach(p => {
        if (p.drawdown && p.drawdown > 0) {
            out.push({ year: p.year, value: p.drawdown });
        }
    });
    return out;
}

export const yearPeriodsFromPayments = (isMonthly: boolean, payments: Array<IPaymentsYear>): Array<IYearValue> => {
    const out: Array<IYearValue> = [];
    let prev: number = 0;
    let sz: number = 0;
    const prevValue = ():number => (isMonthly ? parseFloat((prev/12).toFixed(2)) : prev);
    payments.forEach(p => {
        const current: number = p.payment ? p.payment : 0;
        if (current !== prev) {
            if (prev !== 0 || out.length !== 0) {
                out.push({ year: sz, value: prevValue() });
            }
            sz = 1;
        } else {
            sz ++;
        }
        prev = current;
    });
    if (sz > 1 && prev !== 0) {
        out.push({ year: payments.length - sz - 1, value: prevValue() });
    }
    return out;
}

export const paymentsFromYearPeriods = (
    yearPeriods: Array<IYearValue>,
    isMonthly: boolean,
    payments: Array<IPaymentsYear>
): Array<IPaymentsYear> => {
    // first loop - reset array of payment
    const p = payments.slice().map(p => { p.payment = 0; return p;});
    let pi = 0;
    yearPeriods.forEach(y => {
        for (let yi = 0; yi < y.year; yi ++) {
            p[pi].payment = isMonthly ? y.value*12 : y.value;
            pi++;
        }
    })
    return p;
};

const defaultPayments: Array<IPaymentsYear> = [
    { year: 0 },
    { year: 1, payment: 2200 },
    { year: 2, payment: 2200 },
    { year: 3, payment: 2200 },
    { year: 4, payment: 2200 },
    { year: 5, payment: 2200 },
    { year: 6 },
    { year: 7 },
    { year: 8 },
    { year: 9 },
    { year: 10 },
    { year: 11 },
    { year: 12, drawdown: 10000 },
    { year: 13 },
    { year: 14 },
    { year: 15 },
    { year: 16 },
    { year: 17 },
    { year: 18 },
    { year: 19 },
    { year: 20 },

    { year: 21 },
    { year: 22 },
    { year: 23 },
    { year: 24 },
    { year: 25 },
    { year: 26 },
    { year: 27 },
    { year: 28 },
    { year: 29 },
    { year: 30 },
    { year: 31 },
    { year: 32 },
    { year: 33 },
    { year: 34 },
    { year: 35 },
    { year: 36 },
    { year: 37 },
    { year: 38 },
    { year: 39 },
    { year: 40 },
    { year: 41 },
    { year: 42 },
    { year: 43 },
    { year: 44 },
    { year: 45 },
];

const isDefaultMonthly = false;
export const getDefault = (): IPaymentsInput => {
    return {
        isJoint: false, // h5
        clientAge: 70, // h7
        housePrice: 800000,  // h11
        futureHousePricePct: 1.5, // h123, -5%.. 5%, step 0.25
        loanTaken: 350000,
        interestRatePct: 2.5,
        projection: {
            maxRepaymentPer: "M",
            minRepaymentPer: "M",
            interestRate: "Y",
            equityRetained: isDefaultMonthly ? 'M' : 'Y',
        },
        yearPeriods: yearPeriods,
        drawdowns: drawdownsFromPayments(defaultPayments).slice(),
        payments: defaultPayments,
        numYears: 20
    };
};

const yearPeriods = yearPeriodsFromPayments(isDefaultMonthly, defaultPayments);

export const getLtvValue = (age: number, isJoint: boolean): number => {
    if (age >= 80) return 54.50;
    const values: any = (isJoint) ? {
        55: 28.00, 56: 32.00, 57: 33.00, 58: 34.00, 59: 35.00, 60: 35.80,
        61: 36.60, 62: 37.80, 63: 38.80, 64: 40.00, 65: 40.90, 66: 42.00,
        67: 42.70, 68: 43.90, 69: 45.10, 70: 46.30, 71: 47.50, 72: 49.10,
        73: 50.30, 74: 50.90, 75: 51.40, 76: 52.90, 77: 53.40, 78: 54.00,
        79: 54.40
    } : {
        55: 29.90, 56: 33.00, 57: 34.00, 58: 35.00, 59: 36.00, 60: 36.80,
        61: 37.60, 62: 38.80, 63: 39.80, 64: 41.00, 65: 41.90, 66: 43.00,
        67: 43.60, 68: 44.90, 69: 46.00, 70: 47.20, 71: 48.50, 72: 49.50,
        73: 50.30, 74: 51.50, 75: 52.40, 76: 53.50, 77: 54.40, 78: 54.50,
        79: 54.50
    };
    return values[age] || 0;
};

export const getPaymentYear = (input: IPaymentsInput, n: number): IPaymentsYear => {
    if (n === 0) {
        return { year: 0 };
    }
    const found = input.payments.filter(y => (y.year === n));
    if (found.length > 0) {
        return found[0];
    }
    return { year: n };
};

export const getMonth = (input: IPaymentsInput, n: number, cache: Array<IPaymentsOutputMonth>): IPaymentsOutputMonth => {
    if (n === 0) {
        return {
            payments: 0,
            additionalLoan: 0,
            interestPayment: 0,
            interestNoRepayment: 0,
            balanceE: input.loanTaken,
            balanceJ: input.loanTaken,
        };
    }

    const year: number = (n == 0) ? 0 : (Math.floor((n - 1) / 12) + 1);
    const paymentYear: IPaymentsYear = getPaymentYear(input, year);
    const payments = paymentYear.payment ? (paymentYear.payment / 12.0) : 0;

    const prevMonth: IPaymentsOutputMonth = (cache.length > n - 1) ?
        cache[n - 1] : getMonth(input, n - 1, cache);

    const additionalLoan: number = paymentYear.drawdown ? paymentYear.drawdown / 12.0 : 0;
    const interestPayment: number = (prevMonth.balanceE + additionalLoan) * (input.interestRatePct * 0.01 / 12.0);
    const balanceE = - payments + interestPayment + additionalLoan + prevMonth.balanceE;

    const interestNoRepayment: number = (prevMonth.balanceJ < prevMonth.additionalLoan) ? 0 : (prevMonth.balanceJ * input.interestRatePct * 0.01) / 12.0;
    const balanceJ = additionalLoan + interestNoRepayment + prevMonth.balanceJ;
    return {
        payments,
        additionalLoan,
        interestPayment, // Column D
        balanceE, // Column E
        interestNoRepayment, // Column I,
        balanceJ // Column J
    }
};

export const getTotal = (input: IPaymentsInput, year: number, cache: Array<IPaymentsOutputMonth>): IPaymentsOutputMonth => {
    const sum = {
        payments: 0,
        additionalLoan: 0,
        interestPayment: 0,
        interestNoRepayment: 0,
        balanceE: 0,
        balanceJ: 0
    };

    // let dbg = '';
    for (let mi = (year - 1) * 12 + 1; mi <= (year * 12); mi++) {
        const m = getMonth(input, mi, cache);
        sum.payments += m.payments;
        sum.additionalLoan += m.additionalLoan;
        sum.interestPayment += m.interestPayment;
        sum.interestNoRepayment += m.interestNoRepayment;
        // dbg += mi + ': ' + m.payments + ', ' + m.interestPayment.toFixed(2) + ', ';
    }
    // if (year === 1) {
    //      console.log('year', year, 'from', (year - 1) * 12 + 1, 'to', (year * 12));
    // }
    return sum;
}

export const getYear = (input: IPaymentsInput, n: number, cache: Array<IPaymentsOutputMonth>): IPaymentsOutputYear => {
    if (n === 0) {
        return {
            year: 0,
            futureHousePrice: input.housePrice,
            partialPaymentInterest: 0,
            partialPaymentLoanBalance: Math.min(input.loanTaken, input.housePrice),
            noRepaymentsInterest: 0,
            noRepaymentsLoanBalance: Math.min(input.loanTaken, input.housePrice),
        };
    }
    const prevYear: IPaymentsOutputYear = getYear(input, n - 1, cache);
    const partialPaymentInterest = getTotal(input, n, cache).interestPayment;
    const noRepaymentsInterest = getTotal(input, n, cache).interestNoRepayment;
    return {
        year: n,
        futureHousePrice: prevYear.futureHousePrice *
            (1 + input.futureHousePricePct * 0.01),
        partialPaymentInterest,
        partialPaymentLoanBalance: prevYear.partialPaymentLoanBalance +
            - getTotal(input, n, cache).payments +
            partialPaymentInterest + // Column N - from Column D
            getTotal(input, n, cache).additionalLoan,
        noRepaymentsInterest,
        noRepaymentsLoanBalance: prevYear.noRepaymentsLoanBalance +
            getTotal(input, n, cache).additionalLoan +
            noRepaymentsInterest // Column S - from Column I
    };
};

export const getOutput = (input: IPaymentsInput): IPaymentsOutput => {
    const term = 46;
    const out: IPaymentsOutput = {
        years: [],
        months: [],
        additionalLoan: 0,
        minBorrow: 0,
        maxBorrow: 0,
    }
    for (let mi = 0; mi <= term * 12; mi++) {
        out.months.push(getMonth(input, mi, out.months));
    }
    for (let yi = 0; yi <= term; yi++) {
        out.years.push(getYear(input, yi, out.months));
    }
    out.maxBorrow = getLtvValue(input.clientAge, input.isJoint) * input.housePrice * 0.01;
    out.minBorrow = input.housePrice * 0.05;
    if (out.minBorrow < 10000) {
        out.minBorrow = 10000;
    }
    out.additionalLoan = out.maxBorrow - input.loanTaken;
    return out
};
