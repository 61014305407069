import React from 'react';
import styled from  'styled-components';
// import { fire } from './../../services/Gtag';

const IE11Component = styled.a`
  margin-left: 0.33em;
  margin-right: 0.33em;
`;

const ALink  = styled(IE11Component)`
  @supports (display: grid) {
    margin-inline-start: 0.33em;
    margin-inline-end: 0.33em;
  }
`;

export class ExternalLink extends React.Component {
  render() {
    const title = this.props.title || this.props.children || this.props.href;
    const { href, className, style } = this.props;
    // const onClick = () => (fire({ action: 'LINK', category: 'click', label: href}));
    return (
      <ALink {...{href, className, style}} rel="noopener noreferrer nofollow" target="_blank">
        {title}
      </ALink>
    );
  }
}
