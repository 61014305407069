import React from 'react';

export const Plus: React.FC<any> = () => {
    const height = 17;
    const width = height;
    const mid = height / 2;
    const offs = 5;
    const background = '#000';
    const color = '#fff';

    return (
      <div style={{ height }}>
        <svg
          viewBox={`0 0 ${width} ${height}`}
          style={{ background, width, height }}
        >
          <path
            stroke={color} strokeWidth={2}
            d={`M${offs} ${mid} L${width - offs} ${mid}`}
          />
          <path
            stroke={color} strokeWidth={2}
            d={`M${mid} ${offs} L${mid} ${height - offs}`}
          />
        </svg>
      </div>
    );
};

export const Minus: React.FC<any> = () => {
  const height = 17;
  const width = height;
  const mid = height / 2;
  const offs = 5;
  const background = '#000';
  const color = '#fff';

  return (
    <div style={{ height }}>
      <svg
        viewBox={`0 0 ${width} ${height}`}
        style={{ background, width, height }}
      >
        <path
          stroke={color} strokeWidth={2}
          d={`M${offs} ${mid} L${width - offs} ${mid}`}
        />
      </svg>
    </div>
  );
};
