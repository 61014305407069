import React from 'react';
import styled from 'styled-components';
import { colorConfig } from './../../config/globalConfig';

const Btn = styled.button`

  background: ${colorConfig.main};
  color: white;
  border-radius: 20px;
  border: none;
  padding: 12px 20px;

  text-decoration: none;
  display: block;
  min-width: 300px;
  text-align: center;

  font-family: 'Nunito Sans',arial;
  font-size: 16px;
  cursor: pointer;

  &:hover { background-color: ${colorConfig.main}; }

  &:disabled { background-color: #888 !important; cursor: auto; }
  @media(max-width: 800px) { width: 100%; }
`;

export const Button = ({ id, disabled, className, children, onClick }) => {
  return <Btn {...{ disabled, id, className, onClick}} >{children}</Btn>;
};

export default Button;
