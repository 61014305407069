import styled from 'styled-components';
import { bkColors, colors } from './../../config/partialRepaymentConfig';

// color: #00b0a9;
export const NoRepayment = styled.div`
  background: ${bkColors.noRepayments};
  color: ${colors.noRepayments};
  font-weight: bold;
  font-family: 'Nunito Sans',arial;
  padding: 20px;
  margin-bottom: 3px;
`;

// color: #7030b3
export const PayingOffInterest = styled.div`
  background: ${bkColors.payingOffInterest};
  color: ${colors.payingOffInterest};
  font-weight: bold;
  font-family: 'Nunito Sans',arial;
`;

// color: : '#025959';
export const PartialRepayment = styled.div`
  background: ${bkColors.partialRepayment};
  color: ${colors.partialRepayment}
  font-weight: bold;
  font-family: 'Nunito Sans',arial;
  margin-bottom: 3px;
  .section-row {
    background: transparent;
  }
  .column {
    width: 25%;
  }
`;

export const StopAfter = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 14px;
`;
export const ErrorBox = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  background: #fff;
  font-family: 'Nunito Sans',arial;
  font-size: 12px;
  font-weight: bold;
  color: red;
  text-align: center;
  border: 1px red solid;
  padding: 10px;
`;
