import React from 'react';
import styled from 'styled-components';
import TransparentInput from '../../components/Form/TransparentInput';
import { toPounds } from '../../services/Formats';
import { IPaymentsInput, IPaymentsOutput } from './../../services/types';
import { drawdownsFromPayments, yearPeriodsFromPayments } from '../../services/Calc';

interface IYearsInput {
    input:  IPaymentsInput
    output: IPaymentsOutput
    onChangeInput: Function
    onChangeInputs: Function
}

export const Div = styled.div`
  margin: 0px auto;
  @media(max-width: 860px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  p { text-align: center; margin: 0px; }
  table {
    margin: 0px auto;
    margin-top: 20px;
    min-width: 960px;
    width: 1200px;
    border: 0;
    border-spacing: 0px;
  }
  table thead th.group { background: #93bc22;  color: white; }
  table thead th {
      background: #209546;
      border: 0; color: white;
      font-size: 0.75rem;
      line-height: 2rem;
  }

  table tr td { font-size: 0.75em; line-height: 40px; text-align: right; border-bottom: 1px #cacece solid; }
  table tr:nth-child(odd) td.stripe { background: #e7eaea; text-align: center; }
  table tr:nth-child(even) td.stripe { background: #f8f9f9; text-align: center;}

  table td.light, table td.medium, table td.dark {
    padding-right: 20px; padding-left: 20px;
  }

  table td.light { background: #eff5e4; }
  table td.medium { background: #ddeac4; }
  table td.dark { background: #cee1aa; }

`;

const positive = (x: number): number => ((x >= 0) ? x : 0);

export const YearsInput: React.FC<IYearsInput> = (props: IYearsInput) => {
    const { input, output, onChangeInputs } = props;

    const onChangeYear = (yearIndex: number, field: string, value: any) => {
        const isMonthly = input.projection.equityRetained === 'M';
        const payments = input.payments.slice();
        input.payments.forEach((y, index) => {
            if (y.year === yearIndex + 1) {
                if (field === 'drawdown') {
                    payments[index].drawdown = parseFloat(value) || 0;
                } else if (field === 'payment') {
                    payments[index].payment = parseFloat(value) || 0;
                }
            }
        })
        const change:any = { payments };
        if (field === 'drawdown') {
            change['drawdowns'] = drawdownsFromPayments(payments);
        } else if (field === 'payment') {
            const yearPeriods = yearPeriodsFromPayments(isMonthly, payments);
            change['yearPeriods'] = yearPeriods;
        }
        onChangeInputs(change);
    }
    return (
        <Div>
            <p>
                Below is detailed year-by-year information of how your repayments
                and drawdown will affect the total equity retained.
            </p>
            <p>
                Feel free to amend to see how the result change:
            </p>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th className="group" colSpan={6}>Compound Interest Calculator</th>
                        <th className="group" colSpan={4}>House Price Calculator</th>
                    </tr>
                    <tr>
                        <th>Year</th>
                        <th>Drawdowns</th>
                        <th>1st Jan</th>
                        <th>Interest</th>
                        <th>Payments</th>
                        <th>31st Dec</th>

                        <th>1st Jan</th>
                        <th>Increase</th>
                        <th>31st Dec</th>
                        <th>Equity</th>
                    </tr>
                </thead>
                <tbody>
                    {output.years.slice(0, 40).map((year, index) => {
                        const next = output.years[index + 1];
                        const v = input.payments.filter(y => (y.year === year.year + 1))[0];
                        const drawdown = (v.drawdown) ? v.drawdown : '0';
                        const payment = (v.payment) ? v.payment : '0';
                        return  (
                            <tr key={year.year}>
                                <td className="stripe">{year.year + 1}</td>
                                <td className="light">
                                    <TransparentInput
                                        width={100} style={{ textAlign: 'right' }}
                                        type="currency" prefixes="&pound;"
                                        value={drawdown} onChange={(val:any) => { onChangeYear(year.year, 'drawdown', val) }} />
                                </td>
                                <td className="light">
                                    {toPounds(Math.floor(year.partialPaymentLoanBalance))}
                                </td>
                                <td className="light">
                                    {toPounds(Math.floor(next.partialPaymentLoanBalance - year.partialPaymentLoanBalance))}
                                </td>
                                <td className="light">
                                    <TransparentInput
                                        width={100} style={{ textAlign: 'right' }}
                                        type="currency" prefixes="&pound;"
                                        value={payment} onChange={(val:any) => { onChangeYear(year.year, 'payment', val) }} />
                                </td>
                                <td className="medium">
                                    {toPounds(Math.floor(next.partialPaymentLoanBalance))}
                                </td>

                                <td className="medium">
                                    {toPounds(Math.floor(year.futureHousePrice))}
                                </td>
                                <td className="medium">
                                    {toPounds(Math.floor(next.futureHousePrice - year.futureHousePrice))}
                                </td>
                                <td className="medium">
                                    {toPounds(Math.floor(next.futureHousePrice))}
                                </td>
                                <td className="dark">
                                    {toPounds(positive(Math.floor(next.futureHousePrice-next.partialPaymentLoanBalance)))}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div style={{ marginBottom: 50 }}></div>
        </Div>
    )
};
