
export const colors = {
  futureHousePrice: '#209546', // '#93bc22', // '#62ad4e',
  noRepayments: '#93bc22',  // '#125b2e', // '#00b0a9',
  // payingOffInterest: '#03a6a6', // '#7030a0',
  partialRepayment: '#0d2926', // '#ff0000'
};

export const bkColors = {
  futureHousePrice: '#eaf5ed', // '#f4f8e8',
  noRepayments: '#f1f1f1', // '#d8e4dd', // '#00b0a9',
  payingOffInterest: '#f1f1f1', // '#d9f2f2', // '#f0eaf5', // '#7030a0',
  partialRepayment: '#e6e9e8', // '#e8f0f0' // '#ff0000'
};

export const titles = {
  futureHousePrice: 'Future house price',
  partialRepayment: 'With repayments',
  // payingOffInterest: 'Paying interest',
  noRepayments: 'No repayments'
};

