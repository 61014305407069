import React from 'react';
import styled from 'styled-components';

export interface IMonthYearInput {
    value: string
    onChange: Function
    suffix?: string
    style?: any
}

export interface IOpened {
    opened: boolean
}

const MonthYearDiv = styled.div`
    position: relative; height: 32px;
    button { background: transparent; border: none; outline: none; cursor: pointer; padding: 0px; font-weight: bold; font-size: 16px; }
    .state { display: flex; width: 100%; line-height: 32px; }
    .state .value { background: white; border: 1px #000 solid; flex: 1; }
    .state .arrow { width: 20px; }
    .list { position: absolute; top: 33px; z-index: 10; left: 0px; width: 100%; background: white; }
    .list.opened { display: block; border: 1px #000 solid; }
    .list.closed { display: none; }
    .list button { width: 100%; display: block; line-height: 32px; }
    .list button.selected { background: #000; color: white; }
    .list button:hover { background: #444; color: white; }
`;

export const MonthYear: React.FC<IMonthYearInput> = (props: IMonthYearInput) => {
    const { value, onChange, suffix, style } = props;
    const [state, setState] = React.useState<IOpened>({ opened: false });
    const humanValue: string = ((): string => {
        if (value === 'M') return 'Month' + (suffix ? suffix : '');
        if (value === 'Y') return 'Year' + (suffix ? suffix : '');
        return "";
    })();
    const onToggleState = () => (setState({ opened: !state.opened }));

    return (
        <MonthYearDiv style={{...style}}>
            <button className="state" onClick={onToggleState}>
                <div className="value">
                    {humanValue}
                </div>
                <div className="arrow">
                    <svg viewBox="0 0 20 32" style={{ width: 20, height: 32 }}>
                        <polygon fill="#000" points={
                            state.opened ? "5,21 11,16 16,21" : "5,16 11,21 16,16"
                        } />
                    </svg>
                </div>
            </button>
            <div className={`list ${state.opened ? 'opened' : 'closed'}`}>
                <button
                    onClick={() => { onChange('M'); onToggleState() }}
                    className={`item ${(value === 'M') ? 'selected' : ''}`}
                >
                    Month{suffix ? suffix : ''}
                </button>
                <button
                    onClick={() => { onChange('Y'); onToggleState() }}
                    className={`item ${(value === 'Y') ? 'selected' : ''}`}
                >
                    Year{suffix ? suffix : ''}
                </button>
            </div>
        </MonthYearDiv>
    );
}
