import React from 'react';
import { VoluntaryRepayments } from './../components/VoluntaryRepayments/VoluntaryRepayments';

const App = () => (
  <div className="App">
    <VoluntaryRepayments />
  </div>
);

export default App;
