import React from 'react';
import { CalculatorSection } from './Sections';
import { ClientDetails } from './Panel/ClientDetails';
import { VoluntaryRepaymentsOptions } from './Panel/VoluntaryRepaymentsOptions';
import { DrawdownAdditional } from './Panel/DrawdownAdditional';
import { colors, bkColors } from '../../config/partialRepaymentConfig';
import { Chevron } from './../../components/Form/Chevron';

export interface IVisibility {
  clientDetails: boolean
  repayments: boolean
  drawdowns: boolean
};

const defaultVisibility: IVisibility  = {
  clientDetails: true,
  repayments: false,
  drawdowns: false
};

export const LeftPanel = (props: any) => {
  const { input, output, onChangeInput, onChangeInputs } = props;
  const [state, setState] = React.useState<IVisibility>(defaultVisibility);
  const onToggle1 = () => {
    setState({ clientDetails: true, repayments: false, drawdowns: false });
  };
  const onToggle2 = () => {
    setState({ clientDetails: false, repayments: true, drawdowns: false });
  };
  const onToggle3 = () => {
    setState({ clientDetails: false, repayments: false, drawdowns: true });
  };

  return (
    <div>
      <CalculatorSection key={0} icon='house-price2019' style={{ marginBottom: 10 }}>
        <button className="title" onClick={onToggle1} style={{ backgroundColor: colors.futureHousePrice }}>
          <div className="at-right">
            <Chevron isOpen={state.clientDetails} />
          </div>
          Client details and loan
        </button>
        {state.clientDetails ? <div style={{ height: "15px", background: bkColors.futureHousePrice }}></div> : false }
        {state.clientDetails ? <ClientDetails {...{ input, output, onChangeInput }} /> : false }
      </CalculatorSection>
      <CalculatorSection key={1} icon='loan2019' style={{ marginBottom: 10 }}>
        <button className="title" onClick={onToggle2} style={{ backgroundColor: '#86b42d' }}>
          <div className="at-right">
            <Chevron isOpen={state.repayments} />
          </div>
          Voluntary repayment options
        </button>
        {state.repayments ? <VoluntaryRepaymentsOptions {...{ input, output, onChangeInput, onChangeInputs }} />: false}
      </CalculatorSection>
      <CalculatorSection key={2} icon='repayment2019'>
        <button className="title" onClick={onToggle3}  style={{ backgroundColor: '#0d2926' }}>
          <div className="at-right">
            <Chevron isOpen={state.drawdowns} />
          </div>
          Drawdown of additional amounts
        </button>
        {state.drawdowns ? <DrawdownAdditional {...{ input, output, onChangeInput, onChangeInputs }} />: false }
      </CalculatorSection>
    </div>
  );
}
