import React from 'react';
import styled from 'styled-components';
import RangerInput from '../../components/Form/RangerInput';
import { toPounds } from '../../services/Formats';
import { IPaymentsInput, IPaymentsOutput } from './../../services/types';

interface IYearsSliderInput {
    input:  IPaymentsInput
    output: IPaymentsOutput
    onChangeInput: Function
}

export const Div = styled.div`
    display: flex;
    width: 98%;
    margin-top: 20px;

    .lbl { width: 80px; padding-right: 10px; padding-top: 50px; text-align: right; }
    .main { flex: 1; }

    table.ruler { margin-bottom: 20px; width: 100%; border-spacing: 0; border-collapse: collapse;}
    table.ruler td { text-align: center; font-size: 0.9rem; width: 14px; }
    table.ruler td.dots { font-size: 1rem; }

    table.values { margin-top: 30px; width: 100%;  border-spacing: 0; border-collapse: collapse;}
    table.values td { text-align: center; font-size: 0.9rem; padding: 5px; }
    table.values tr:last-child td { border-top: 2px #888 solid; }
`;

export const YearsSlider: React.FC<IYearsSliderInput> = (props: IYearsSliderInput) => {
    const { input, output, onChangeInput } = props;
    const ruler = [0, 5, 10, 15, 20, 25, 30, 35, 40];
    const v = output.years[input.numYears];
    return (
        <Div>
            <div className="lbl">Year</div>
            <div className="main">
                <table className="ruler">
                    <tbody>
                        <tr>
                            {ruler.map(r => [
                                <td key={r}>{r}</td>,
                                <td key={r + 1} className="dots">: : : :</td>
                            ])}
                            <td key={45}>45</td>
                        </tr>
                    </tbody>
                </table>

                <div>
                    <RangerInput
                        value={input.numYears} min={0} max={45}
                        inactiveColor='#bbbbbb' withLabel={true}
                        color={'#209546'}
                        onChange={(val: any) => { onChangeInput('numYears', val) }}  />
                </div>
                <table className="values">
                    <tbody>
                        <tr>
                            <td>
                                <strong>House Price</strong>
                            </td>
                            <td>
                                <strong>Loan balance</strong>
                                &nbsp;(no repayments)
                            </td>
                            <td>
                                <strong>Loan balance</strong>
                                &nbsp;(with repayments)
                            </td>
                        </tr>
                        <tr>
                            <td>{toPounds(v.futureHousePrice)}</td>
                            <td>{toPounds(v.noRepaymentsLoanBalance)}</td>
                            <td>{toPounds(v.partialPaymentLoanBalance)}</td>
                        </tr>
                        <tr>
                            <td><strong>Remaining equity</strong></td>
                            <td>{toPounds(v.futureHousePrice - v.noRepaymentsLoanBalance)}</td>
                            <td>{toPounds(v.futureHousePrice - v.partialPaymentLoanBalance)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Div>
    )
};
