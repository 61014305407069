import React from 'react';
import styled from 'styled-components';

const ColorLine = ({ color }) => (
  <svg viewBox="0 0 40 10" style={{ width: 40, height: 10 }}>
    <rect width={30} y={3} height={4} fill={color} />
  </svg>
);

const Wrapper = styled.div`
  font-family: 'Nunito Sans',arial;
  margin-bottom: 20px;
  background: #f3f7ea;
  padding: 30px;

  .title {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .item {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: #000;
    width: 190px;
    float: left;
    white-space: nowrap;
  }
  .bordered {
    border: 1px #888 solid;
    padding: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    display: flex;
  }
`;

export const ChartLegend = ({ colors, titles }) => (
  <Wrapper>
    <div className="title">
      Hover over the dotted lines on the graph to see the equity retained at that point
    </div>
    <div className="bordered">
      <div style={{ width: 120 }}>Graph key</div>
      <div style={{ flex: 1 }}>
        {Object.keys(titles).map(id => (
          <div key={id} className="item">
            <ColorLine color={colors[id]} />
            {titles[id]}
          </div>
        ))}
      </div>
    </div>
  </Wrapper>
);
