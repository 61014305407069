import React from 'react';
import { ExternalLink } from './../../Form/ExternalLink';
import { Exclamation } from './../../Form/Exclamation';
import { MonthYear } from './../../Form/MonthYear';
import { TextInput, RangerInput, Plus, Minus } from './../../Form/index';
import ReadOnlyInput from './../../Form/ReadOnlyInput';
import { toCurrency } from './../../../services/Formats';
import { paymentsFromYearPeriods } from './../../../services/Calc';

export const VoluntaryRepaymentsOptions = ({ input, output, onChangeInput, onChangeInputs }) => {
  const isMonthly = (input.projection.equityRetained === 'M');

  const onAddRow = () => {
    // find the first year without the payment
    const found = input.payments.find(y => (!y.payment));
    const year = (found) ? found.year : 1;
    const yearPeriods = input.yearPeriods.slice();
    yearPeriods.push({ year, value: 0 });
    onChangeInput('yearPeriods', yearPeriods);
  }

  const canRemove = () => {
    return (input.yearPeriods.length > 0);
  };

  const onRemoveLastRow = () => {
    const yearPeriods = input.yearPeriods.slice();
    yearPeriods.pop();
    onChangeInputs({
      'yearPeriods':  yearPeriods,
      'payments': paymentsFromYearPeriods(yearPeriods, isMonthly, input.payments),
    });
  };


  const onChangeYear = (row, value) => {
    const yearPeriods = input.yearPeriods.slice();
    yearPeriods[row].year = value;

    onChangeInputs({
      'yearPeriods':  yearPeriods,
      'payments': paymentsFromYearPeriods(yearPeriods, isMonthly, input.payments),
    });
  };

  const onChangeValue = (index, value) => {
    const yearPeriods = input.yearPeriods.slice();
    yearPeriods[index].value = value;
    onChangeInputs({
      'yearPeriods': yearPeriods,
      'payments': paymentsFromYearPeriods(yearPeriods, isMonthly, input.payments)
    });
  };

  const minRepayment = 0;
  // should be 10% of “Desired initial loan amount”.
  const maxRepaymentYearly = 0.1 * input.loanTaken;
  const maxRepayment = (input.projection.maxRepaymentPer === 'M') ?  Math.floor(maxRepaymentYearly/12) : maxRepaymentYearly;

  // use column D in case of Monthly
  // use column N in case of Yearly
  const interestRateY = output.years[1].noRepaymentsInterest;
  const interestRateM = output.months[1].interestNoRepayment;
  const interestRate = Math.floor((input.projection.interestRate === 'M') ? interestRateM : interestRateY);

  return (
    <div>
      <div className="section-row" style={{ background: "#eff5e4" }}>
        <div className="pad" style={{ fontSize: '0.75rem' }}>
          <div className="label" style={{ fontWeight: 600 }}>
            Repayment Options
        </div>
        </div>
      </div>
      <div className="section-row" style={{ background: "#eff5e4" }}>
        <div className="pad" style={{ fontSize: '0.8rem' }}>
          <div>
            Throughout your loan period, you can choose to make
          <ExternalLink to='#' style={{ color: '#86b42d', whiteSpace: 'nowrap' }}>
              voluntary repayments
          </ExternalLink>. Here are some details
          about how much you can repay on your loan:
        </div>
        </div>
      </div>
      <div className="section-row" style={{ background: "#eff5e4" }}>
        <div className="pad">
          <div className="label" style={{ marginLeft: 20, fontSize: '1rem' }}>
            Maximum
          <br />
          repayment every
        </div>
          <div style={{ marginRight: 5 }}>
            <MonthYear
              style={{ width: 85 }}
              value={input.projection.maxRepaymentPer}
              onChange={maxRepaymentPer => {
                onChangeInput('projection', {...input.projection, maxRepaymentPer });
              }}
            />
          </div>
          <ReadOnlyInput
            width={100} style={{ textAlign: 'right' }}
            type="currency" prefixes="&pound;"
            value={toCurrency(maxRepayment)}
          />
        </div>
      </div>
      <div className="section-row" style={{ background: "#eff5e4" }}>
        <div className="sm">without incurring an ERC</div>
      </div>
      <div className="section-row" style={{ background: "#eff5e4" }}>
        <div className="pad" style={{ fontSize: '0.75rem' }}>
          <div className="label" style={{ marginLeft: 20, fontSize: '1rem' }}>
            Minimum
          <br />
          repayment every
        </div>
          <div style={{ marginRight: 5 }}>
            <MonthYear
              style={{ width: 85 }}
              value={input.projection.minRepaymentPer}
              onChange={minRepaymentPer => {
                onChangeInput('projection', {...input.projection, minRepaymentPer });
              }}
            />
          </div>
          <ReadOnlyInput
            width={100} style={{ textAlign: 'right' }}
            type="currency" prefixes="&pound;"
            value={toCurrency(minRepayment)}
          />
        </div>
      </div>
      <div className="section-row" style={{ background: "#eff5e4" }}>
        <div className="pad" style={{ fontSize: '0.75rem' }}>
          <div className="label" style={{ marginLeft: 20, fontSize: '1rem' }}>
            Interest rate
          <br />
          on loan in first
        </div>
          <div style={{ marginRight: 5 }}>
            <MonthYear
              style={{ width: 85 }}
              value={input.projection.interestRate}
              onChange={interestRate => {
                onChangeInput('projection', {...input.projection, interestRate });
              }}
            />
          </div>
          <ReadOnlyInput
            width={100} style={{ textAlign: 'right' }}
            type="currency" prefixes="&pound;"
            value={toCurrency(interestRate)} onChange={() => { }}
          />
        </div>
      </div>
      <div className="section-row" style={{ background: "#eff5e4" }}>
        <div className="sm">when no repayments are made</div>
      </div>
      <div style={{height: 20}} style={{ background: "#eff5e4" }}>&nbsp;</div>
      <div className="section-row" style={{ background: "#eff5e4" }}>
        <div className="pad" style={{ fontSize: '0.75rem' }}>
          <div className="label" style={{ fontSize: '1rem', fontWeight: 600 }}>
            See how repayments
          <br />
          affect the equity retained
        </div>
          <div style={{ marginRight: 10, marginLeft: 20, fontSize: '0.66rem' }}>
            Repay
        </div>
          <div>
            <MonthYear
              style={{ width: 100 }}
              suffix="ly"
              value={input.projection.equityRetained}
              onChange={equityRetained => {
                onChangeInputs({
                  'projection': {...input.projection, equityRetained },
                  'payments': paymentsFromYearPeriods(input.yearPeriods, equityRetained === 'M', input.payments)
                })
              }}
            />
          </div>
        </div>
      </div>

      {input.yearPeriods.map((row, index) => {
        const isError = parseFloat(row.value) > maxRepayment;
        return (
          <div className="section-row" key={index} style={{ background: "#eff5e4" }}>
            {isError ? (
              <div className="error-hint">
                <div className="tbl-row">
                  <div className="icon"><Exclamation /></div>
                  <div className="box">
                    Amount exceeds maximum repayment, <br />
                    without incurring an ERC charge. <br />
                    See <u>policy features</u> for full details
                  </div>
                </div>
              </div>
            ): null}
            <div className="pad" style={{ fontSize: '0.75rem' }}>
              <div className="label" style={{ fontSize: '1rem' }}>
                Repayment
                <br />
                period {index + 1}
              </div>
              <div style={{ marginRight: 20 }}>
                <RangerInput
                  sideLabel="Years"
                  className="ranger"
                  color={'#000'}
                  value={row.year} min={1} max={10}
                  inactiveColor='#bbbbbb' withLabel={true}
                  onChange={val => { onChangeYear(index, val) }} />
              </div>
              <div>
                <TextInput
                  className={(isError) ? 'error' : '' }
                  width={120} style={{ textAlign: 'right' }}
                  type="currency" prefixes="&pound;"
                  value={row.value}
                  onChange={val => { onChangeValue(index, parseFloat(val, 10)) }}
                />
              </div>
            </div>
          </div>
        );
      })}

      <div className="section-row" style={{ background: "#eff5e4" }}>
        <div className="pad" style={{ fontSize: '0.8rem' }}>
          <button className="plus" onClick={onAddRow}>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
              <div style={{ flex: 1 }}>&nbsp;</div>
              <div>
                Add repayment period &nbsp;
              </div>
              <Plus />
            </div>
          </button>
        </div>
      </div>

      {canRemove() ? (
        <div className="section-row" style={{ background: "#eff5e4" }}>
          <div className="pad" style={{ fontSize: '0.8rem' }}>
            <button className="plus" onClick={onRemoveLastRow}>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>&nbsp;</div>
                <div>
                  Remove repayment period &nbsp;
                </div>
                <Minus />
              </div>
            </button>
          </div>
        </div>
      ) : false}


      <div className="section-row" style={{ background: "#eff5e4", height: 20 }}>
      </div>
    </div>
  );
}
