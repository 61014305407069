import React from 'react';
import { ErrorBox } from './../Sidebar';
import { Exclamation } from './../../Form/Exclamation';
import { TextInput, RangerInput, RadioButton } from './../../Form/index';
import ReadOnlyInput from './../../Form/ReadOnlyInput';
import { toCurrency } from './../../../services/Formats';
import { colors, bkColors } from './../../../config/partialRepaymentConfig';

export const ClientDetails = ({ input, output, onChangeInput }) => {
  const errMax = (input.loanTaken > output.maxBorrow);
  return (
    <div>
      <div className="section-row" style={{ background: bkColors.futureHousePrice }}>
        <div className="pad">
          <RadioButton
            checked={!input.isJoint}
            value={false}
            label='Single life'
            onChange={val => { onChangeInput('isJoint', false) }}
            color={'#000'}
          />
          <RadioButton
            checked={input.isJoint}
            value={true}
            label='Joint life'
            onChange={val => { onChangeInput('isJoint', true) }}
            color={'#000'}
          />
        </div>
      </div>

      <div className="section-row" style={{ background: bkColors.futureHousePrice }}>
        <div className="pad">
          <div className="label">Client age</div>
          <RangerInput
            className="ranger"
            style={{ width: 120 }}
            color={colors.futureHousePrice}
            value={input.clientAge} min={55} max={90}
            inactiveColor='#bbbbbb' withLabel={true}
            onChange={val => { onChangeInput('clientAge', val) }}
          />
        </div>
      </div>
      {(input.clientAge < 55 || input.clientAge > 95) ? (
        <div className="section-row">
          <div style={{ marginLeft: 20, marginRight: 20, width: '100%' }}>
            <ErrorBox style={{ fontSize: 14, marginTop: 0 }}>
              Please enter client age between 55 and 95
          </ErrorBox>
          </div>
        </div>
      ) : false}
      <div className="section-row" style={{ background: bkColors.futureHousePrice }}>
        <div className="pad" style={{ fontSize: '0.75em' }}>
          You need to be 55 or older to qualify for equity release
        </div>
      </div>

      <div className="section-row" style={{ background: bkColors.futureHousePrice }}>
        <div className="pad">
          <div className="label">Current house price</div>
          <div>
            <TextInput
              width={120} style={{ textAlign: 'right' }}
              type="currency" prefixes="&pound;" value={input.housePrice} onChange={val => { onChangeInput('housePrice', val) }} />
          </div>
        </div>
      </div>
      <div className="section-row" style={{ background: bkColors.futureHousePrice }}>
        <div className="pad" style={{ paddingTop: 5 }}>
          <div className="label">Future value (%)</div>

          <RangerInput
            className="ranger"
            style={{ width: 120 }}
            color={colors.futureHousePrice}
            value={input.futureHousePricePct} min={-5} max={5} div={4} type='float'
            inactiveColor='#bbbbbb' withLabel={true}
            onChange={val => { onChangeInput('futureHousePricePct', val) }}
          />
        </div>
      </div>
      <div className="section-row" style={{ background: bkColors.futureHousePrice }}>
        <div className="pad" style={{ fontSize: '0.75em' }}>
          See a projection of the future value of your client’s home with an
          annual average increase or decrease of -5 to 5%
      </div>
      </div>
      <div style={{ height: "15px", background: bkColors.futureHousePrice }}></div>
      <div style={{ height: "4px" }}></div>
      <div style={{ height: "15px", background: bkColors.futureHousePrice }}></div>

      <div className="section-row" style={{ background: bkColors.futureHousePrice }}>
        <div className="pad" style={{ paddingTop: 5 }}>
          <div className="label">Based on the information above, the:</div>
        </div>
      </div>
      <div className="section-row" style={{ background: bkColors.futureHousePrice }}>
        <div className="pad">
          <div className="label gray">Minimum you<br />could borrow is:</div>
          <div>
            <ReadOnlyInput
              width={120} style={{ textAlign: 'right' }}
              type="currency" prefixes="&pound;"
              value={toCurrency(output.minBorrow)} onChange={() => { }} />
          </div>
        </div>
      </div>
      <div className="section-row" style={{ background: bkColors.futureHousePrice }}>
        <div className="pad">
          <div className="label gray">Maximum you<br />could borrow is:</div>
          <div>
            <ReadOnlyInput
              className={ errMax ? 'error': '' }
              width={120} style={{ textAlign: 'right' }}
              type="currency" prefixes="&pound;"
              value={toCurrency(output.maxBorrow)} onChange={() => { }} />
          </div>
        </div>
      </div>

      <div className="section-row" style={{ background: bkColors.futureHousePrice }}>
        {errMax ? <div className="error-hint">
          <div className="tbl-row">
            <div className="icon"><Exclamation /></div>
            <div className="box">The loan amount exceed<br />the maximum available</div>
          </div>
        </div> : null}
        <div className="pad">
          <div className="label">Desired initial<br />loan amount:</div>
          <div>
            <TextInput
              className={ errMax ? 'error': '' }
              width={120} style={{ textAlign: 'right' }}
              type="currency" prefixes="&pound;"
              value={input.loanTaken}
              onChange={val => { onChangeInput('loanTaken', parseFloat(val, 10)) }}
            />
          </div>
        </div>
      </div>

      <div className="section-row" style={{ background: bkColors.futureHousePrice }}>
        <div className="pad">
          <div className="label gray">Additional loan<br />facility available:</div>
          <div>
            <ReadOnlyInput
              width={120} style={{ textAlign: 'right' }}
              type="currency" prefixes="&pound;"
              value={toCurrency(output.additionalLoan)} onChange={() => { }} />
          </div>
        </div>
      </div>

      <div className="section-row" style={{ background: bkColors.futureHousePrice }}>
        <div className="pad" style={{ paddingTop: 15 }}>
          <div className="label">Loan MER (%)</div>
          <div>
            <RangerInput
              className="ranger"
              color={colors.futureHousePrice}
              value={input.interestRatePct} min={2.5} max={8} type='float' div={20}
              inactiveColor='#bbbbbb' withLabel={true}
              onChange={val => { onChangeInput('interestRatePct', val) }} />
          </div>
        </div>
      </div>

      <div className="section-row" style={{ background: bkColors.futureHousePrice }}>
        <div className="pad" style={{ fontSize: '0.75em' }}>
          Loan APR varies from a minimum 2.5 to a maximum of 8%
      </div>
      </div>
    </div>
  );
};
