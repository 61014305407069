import React from 'react';
import styled from 'styled-components';
import ContainerDimensions from 'react-container-dimensions';
import { AxisX, AxisY, ChartLine, ChartHover, NoData } from './ChartAxis';
import { colors, titles } from './../../config/partialRepaymentConfig';

const SvgWrapper = styled.div`
  .x-label { font-family: 'Nunito Sans',arial; font-size: 12px; fill: #000; }
  .y-label { font-family: 'Nunito Sans',arial; font-size: 12px; fill: #000; font-weight: 400; }
  svg .grid-line { stroke: #bbb; }
  svg .grid-line.active { stroke: #62ad4e; }
  svg .r:hover rect { fill: #f8fff8; }
  svg .r .grid-hoverline { display: none; }
  svg .r:hover .grid-hoverline { display: block; }

  svg text.no-data { font-family: 'Nunito Sans',arial; font-size: 22px; font-weight: bold; text-align: center; }
  svg rect.tip { fill: #fff; stroke: #888; box-shadow: 2px 10px 20px #888; stroke-width: 0.5px; color: #888; }
  svg text.legend { font-family: 'Nunito Sans',arial; font-size: 14px; font-weight: normal; }
`;

// const minValue = arr => (arr.reduce((acc, sum) => (Math.min(acc, sum)), Infinity));
const maxValue = arr => (arr.reduce((acc, sum) => (Math.max(acc, sum)), 0));
// const roundDown = (value, precision) => ( parseInt(value / precision, 10) * precision );
const roundUp = (value, precision) => ( (parseInt(value / precision, 10) + 1) * precision );


export const ChartSeries = ({ series, hideChart }) => {
  const { futureHousePrice, noRepayments, partialRepayment } = series;
  const minY = 0;
  const maxY = roundUp(Math.max(
    maxValue(futureHousePrice), maxValue(noRepayments), maxValue(partialRepayment)
  ), 10000);
  const minX = 0;
  const maxX = 40;
  const stepX = 5;

  const pad = { left: maxY.toString().length * 7, bottom: 20 };
  const textColor = '#000';

  return (
    <div>
      <ContainerDimensions>
        {({ width }) => {
          if (!width) return false;
          const height = width * 0.66;
          const viewBox = `0 0 ${width} ${height}`;
          return (
            <SvgWrapper>
              <svg {...{width, height, viewBox}}>
                <defs>
                  <filter id="dropshadow" height="130%">
                    <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
                    <feOffset dx="2" dy="2" result="offsetblur"/>
                    <feComponentTransfer>
                      <feFuncA type="linear" slope="0.5"/>
                    </feComponentTransfer>
                    <feMerge>
                      <feMergeNode/>
                      <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                  </filter>
                </defs>
                <AxisY {...{width, height, minY, maxY, pad, color: "#000", textColor }} />
                <AxisX {...{width, height, minX, maxX, pad, color: "#000", textColor, stepX }} />
                {!hideChart ? ([
                  <ChartLine {...{key: 'l1', width, height, minX, maxX, minY, maxY, pad, color: colors.noRepayments, data: noRepayments }} />,
                  <ChartLine {...{key: 'l3', width, height, minX, maxX, minY, maxY, pad, color: colors.partialRepayment, data: partialRepayment }} />,
                  <ChartLine {...{key: 'l0', width, height, minX, maxX, minY, maxY, pad, color: colors.futureHousePrice, data: futureHousePrice }} />,
                  <ChartHover {...{ key: 'h', width, height, minX, maxX, minY, maxY, pad, colors, titles, series }} />
                ]): (
                  <NoData {...{width, height, pad }}/>
                )}
              </svg>
            </SvgWrapper>
          );
        }}
      </ContainerDimensions>
    </div>
  );
};
