import React from 'react';

export const Exclamation: React.FC<any> = () => {
    const height = 22;
    const width = height;
    const background = '#fff';
    const color = '#c00';

    return (
      <div style={{ height }}>
        <svg
          viewBox={`0 0 ${width} ${height}`}
          style={{ width, height }}
        >
          <circle
            cx="11" cy="-1041.36" r="8"
            transform="matrix(1 0 0-1 0-1030.36)"
            opacity=".98"
            fill={color} />
          <path
            d="m-26.309 18.07c-1.18 0-2.135.968-2.135 2.129v12.82c0 1.176.948 2.129 2.135 2.129 1.183 0 2.135-.968 2.135-2.129v-12.82c0-1.176-.946-2.129-2.135-2.129zm0 21.348c-1.18 0-2.135.954-2.135 2.135 0 1.18.954 2.135 2.135 2.135 1.181 0 2.135-.954 2.135-2.135 0-1.18-.952-2.135-2.135-2.135z"
            transform="matrix(.30056 0 0 .30056 18.902 1.728)"
            fill={background} stroke={background} />
        </svg>
      </div>
    );
};

