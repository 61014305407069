import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import Debounced from './services/Debounced';
import { triggerResize } from './services/screen';

if (document.getElementById('root')) {
  const notifyResize = () => {
    if (window && window.parent && window.document) {
      const body = window.document.querySelector("body");
      body && window.parent.postMessage({
        origin: window.location.href,
        height: body.clientHeight + 40
      }, '*');
    }
  };

  const Root = () => (<App />);
  ReactDOM.render(<Root />, document.getElementById('root'));
  window.addEventListener('resize', () => { Debounced.start('resize', notifyResize, 250); });
  triggerResize('index');
} else {
  console.error('nothing to render, root not found');
}
