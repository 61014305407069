import React from 'react';
import { Tooltip } from './../../components/Form/Tooltip';

export const AxisX = ({width, height, minX, maxX, pad, color, textColor, stepX }) => {
  const { left, bottom } = pad;
  const lineStyle = { stroke: color, strokeWidth: 2 };
  const gridLineStyle = { strokeWidth: 1, strokeDasharray: '3 3' };
  const labels = [];
  const grid = [];
  const dx = (width - left)/ (maxX - minX);
  for (let x = minX; x <= maxX; x ++) {
    const screenX = dx * (x - minX) + left;
    if (x !== minX) {
      grid.push(<line key={`line-${x}`} className='grid-line' x1={screenX} y1={0} x2={screenX} y2={height - bottom} style={gridLineStyle} />);
    }
  }
  for (let x = minX; x < maxX; x += stepX) {
    const screenX = dx * (x - minX) + left;
    const xAdjusted = (screenX > (width - 30)) ? screenX - 50 : screenX;
    labels.push(<text key={x} x={xAdjusted} y={height - bottom + 15} className="x-label" fill={textColor}>{x} years</text>)
  }
  return labels.concat(grid).concat(
   <line key='line' x1={left} y1={height - bottom} x2={width} y2={height - bottom} style={lineStyle} />
  );
};

const round1000 = y => (parseInt(y / 1000, 10) + 'K');

export const AxisY = ({ width, height, minY, maxY, pad, color, textColor }) => {
  const { left, bottom } = pad;
  const lineStyle = { stroke: color, strokeWidth: 2 };
  const labels = [];
  const dy = (height - bottom) / (maxY - minY);

  const stepY = ( maxY - minY ) / 5;
  for (let y = minY; y <= maxY; y += stepY) {
    const screenY = height - bottom - dy * (y - minY);
    // const xAdjusted =  (screenX > (width - 30)) ? screenX - 50 : screenX;
    const yLabel  = '£' + round1000(y);
    labels.push(<text key={y} x={0} y={screenY + ((y !== minY) ? 10 : 0)} className="y-label">{yLabel}</text>)
  }

  return labels.concat(
    <line key='line' x1={left} y1={0} x2={left} y2={height - bottom} style={lineStyle} />
  );
};

export const ChartLine = ({ width, height, minX, maxX, minY, maxY, pad, color, data }) => {
  const { left, bottom } = pad;
  const style = { stroke: color, strokeWidth: 5 };
  const dx = (width - left)/ (maxX - minX);
  const dy = (height - bottom) / (maxY - minY);
  const screenY = y => (parseInt(height - bottom - dy * (y - minY), 10));

  let d = `M ${left} ${screenY(data[0])}`;
  let i = 0;
  for (let x = minX; x <= maxX; x ++, i++) {
    if (typeof data[i] === 'undefined') break;
    const screenX = parseInt( dx * (x - minX) + left, 10);
    const y = screenY(data[i]);
    d += ` L${screenX} ${y}`;
  }
  return <path {...{d, style}} fill="none" />
};

const toCurrency = x => {
  const val = x.toString().replace(/([a-zA-Z]|,)/g, '');
  if (!isNaN(parseInt(val, 10))) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return val;
};

export const ChartHover = ({ width, height, minX, maxX, minY, maxY, pad, colors, titles, series }) => {
  const { left, bottom } = pad;
  const rectangles = [];
  const dx = (width - left)/ (maxX - minX);
  const rectStyle = { opacity: 0.5 };
  const gridLineStyle = { stroke: '#62ad4e', strokeWidth: 3, strokeDasharray: '3 3' };

  for (let x = minX; x <= maxX; x ++) {
    const screenX = dx * (x - minX) + left;
    if (x !== minX) {
      const barRef = React.createRef();
      rectangles.push(
        <g className="r" key={`g-${x}`}>
          <rect
            ref={barRef}
            key={`rec-${x}`} x={screenX - dx / 2 + 1} y={0} width={dx - 2} height={height - bottom - 3}
            style={rectStyle} fill="transparent"
          />
          <line key={`hoverline-${x}`} className='grid-hoverline' x1={screenX} y1={0} x2={screenX} y2={height - bottom} style={gridLineStyle} />
        </g>
      );

      const labels = Object.keys(titles)
        .filter(id => id !== 'futureHousePrice')
        .filter(id => (typeof series[id][x] !== 'undefined'));

      const tooltipHeight = labels.length * 25 + 50;
      const tooltipWidth = 230;
      const startX = ( x < (minX + maxX) / 2 ) ? 2 : -tooltipWidth;
      const startY = 2;
      const hrY = startY + 38;

      rectangles.push(
        <Tooltip key={x} for={barRef} height={tooltipHeight}>
          <rect className="tip" filter="url(#dropshadow)" x={startX} y={startY} width={tooltipWidth} height={tooltipHeight} rx={.5} ry={.5} />
          <text key={0} className="legend" x={startX + 15} y={startY + 25} fill="#000" style={{ fontWeight: "bold" }}>Retained equity at {x} years</text>
          <line className="hr" x1={startX + 15} x2={startX + tooltipWidth - 15} y1={hrY} y2={hrY} stroke={'#000'} />
          {labels.map((id, index) => {
            const moneyValue = parseInt(series['futureHousePrice'][x] - series[id][x], 10);
            return [(
              <text key={`${id}-text`} className="legend" x={startX + 15} y={startY + 35 + (index+1)*25 } fill={'#000'}>
                {titles[id]}:
              </text>
            ), (
              <text key={`${id}-num`} className="legend" x={startX + 140} y={startY + 35 + (index+1)*25 } fill={colors[id]}>
                &pound;{toCurrency(moneyValue)}
              </text>
            )];
          })}
        </Tooltip>
      );
    }
  }

  return rectangles;
};

export const NoData = ({ width, height, pad }) => {
  const rectStyle = { opacity: '0.5' };
  const { top, left } = pad;
  return (
    <rect x={left} y={top} width={width - left} height={height - top} style={rectStyle} fill="#8a8" />,
    <text className="no-data" x={width / 2 - 50} y={height / 2 - 20} width={width} fill='#aaa'>NO DATA</text>
  );
};
